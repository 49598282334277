



















import Vue from 'vue'
import { mapGetters } from 'vuex'
import { createElipsis } from '~/components/PassporterUI/helpers/Elipsis'

export default Vue.extend({
	name: 'DescriptionToggle',
	props: {
		description: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			default: '',
		},

		maxLength: {
			type: Number,
			default: 250,
		},
		shortLength: {
			type: Number,
			default: 150,
		},
	},
	data() {
		return {
			seeFullText: false,
		}
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
		}),
		needsToggle(): Boolean {
			return this.description.length > this.maxLength
		},
		shortText(): string {
			let limitText = this.maxLength
			if (this.screenSize.xs) {
				limitText = this.shortLength
			}
			return createElipsis(this.description, limitText)
		},
	},
	methods: {
		toogleText(seeMoreButton: any, seeLessButton: any): void {
			this.seeFullText = !this.seeFullText
			const buttonName = this.seeFullText ? seeMoreButton : seeLessButton
			this.$emit('click', buttonName)
		},
	},
})
