import Product from '~/models/Product'
import Price from '~/models/Price'
import Currency from '~/models/Currency'

interface ApiProduct {
	id: string
	title: string
	category: 'trip'
	prices: { currency: string; price: number }[]
	external_url: string
	cover_urls: string[]
}

const parseProduct = (product: ApiProduct) => {
	let price
	if (product.prices?.length) {
		const apiPrice = product.prices[0]
		const currency = new Currency({ code: apiPrice.currency })
		price = new Price({
			currency,
			value: apiPrice.price,
		})
	}

	return new Product({
		id: product.id,
		title: product.title,
		category: product.category,
		externalUrl: product.external_url,
		coverUrls: product.cover_urls,
		price,
	})
}
const parseProducts = (products: ApiProduct[]) => {
	return products.map((product: ApiProduct) => parseProduct(product))
}

export default () => ({
	parseProduct,
	parseProducts,
})
