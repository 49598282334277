import { ApiNote } from '~/passporter-services/notes/apiTypes'

const resource = 'v1/itineraries'

export default (http: any) => ({
	async createNote(itineraryId: string, note: ApiNote) {
		return await http.post(`${resource}/${itineraryId}/notes/`, note)
	},
	async editNote(itineraryId: string, noteId: string, note: ApiNote) {
		return await http.patch(`${resource}/${itineraryId}/notes/${noteId}/`, note)
	},
	async deleteNote(itineraryId: string, noteId: string) {
		return await http.delete(`${resource}/${itineraryId}/notes/${noteId}/`)
	},
	async addMedia(itineraryId: string, noteId: string, medias: string[]) {
		return await http.post(`${resource}/${itineraryId}/notes/${noteId}/medias/`, {
			media_ids: medias,
		})
	},
	async getPaginatedNotes(itineraryId: string, page: number, invitationcode?: string) {
		return await http.get(`${resource}/${itineraryId}/notes/`, {
			params: {
				page,
				page_size: 10,
				code: invitationcode,
			},
		})
	},
	async getNotesList(itineraryId: string, invitationcode?: string) {
		return await http.get(`${resource}/${itineraryId}/notes/list/`, {
			params: {
				code: invitationcode,
			},
		})
	},
	async getMedias(itineraryId: string, noteId: string) {
		return await http.get(`${resource}/${itineraryId}/notes/${noteId}/medias/`)
	},
})
