export class CookieService {
	static setCookie(name: string, value: string, days: number): void {
		const expires = new Date()
		expires.setDate(expires.getDate() + days)

		const cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/;`
		document.cookie = cookie
	}

	static getCookies(): Record<string, string> {
		const cookies = document.cookie
		if (!cookies) return {}

		const cookieList: Record<string, string> = {}
		cookies.split(';').forEach((cookie) => {
			const parts = cookie.split('=')
			cookieList[parts[0].trim()] = parts.slice(1).join('=')
		})

		return cookieList
	}

	static getCookie(name: string): string {
		const cookies = this.getCookies()
		return cookies[name] || ''
	}
}
