import { SpotEntityMapper } from '../spot/entityMapper'
import { ApiItineraryCategory, ApiItineraryGroup, ApiItinerarySpot, ApiProduct } from './apiTypes'
import ItineraryCategory from '~/models/ItineraryCategory'
import ItineraryGroup from '~/models/ItineraryGroup'
import Currency from '~/models/Currency'
import Price from '~/models/Price'
import Product from '~/models/Product'
import { Itinerary2 as Itinerary } from '~/models/Itinerary'
import { ItinerarySpot2 as ItinerarySpot } from '~/models/ItinerarySpot'

export class ItineraryEntityMapper {
	static toItinerary(itinerary: any): Itinerary {
		return {
			id: itinerary.id || null,
			referenceType: 'itinerary',
			covers: itinerary.covers || [],
			numDays: itinerary.num_days,
			numNotes: itinerary.num_notes,
			numDestinations: itinerary.num_destinations || 0,
			numSpots: itinerary.num_spots || 0,
			numTravelers: itinerary.num_travelers || 1,
			isPrivate: itinerary.state === 'private',
			name: itinerary.title || '',
			startDate: itinerary.start_date,
			endDate: itinerary.end_date,
			travelersPictures: itinerary.travelers_pictures || [],
			agencyId: itinerary.agency_id,
			cover: itinerary.cover,
			creatorId: itinerary.creator_id,
			numViews: itinerary.num_views,
		}
	}

	static toItineraries(itineraries: any[]): Itinerary[] {
		return itineraries.map((itinerary: any) => this.toItinerary(itinerary))
	}

	static toItinerarySpot({
		spot,
		groups,
		categories,
		is_reference: isReference,
	}: ApiItinerarySpot): ItinerarySpot {
		const parsedSpot = SpotEntityMapper.toSpot(spot)
		const parsedGroups = groups && this.parseItineraryGroups(groups)
		const parsedCategories = categories && this.parseItineraryCategories(categories)
		return {
			...parsedSpot,
			isReference,
			groups: parsedGroups,
			categories: parsedCategories,
		}
	}

	static parseItinerarySpots = (itinerarySpots: ApiItinerarySpot[]): ItinerarySpot[] => {
		return itinerarySpots.map((itinerarySpot: ApiItinerarySpot) =>
			this.toItinerarySpot(itinerarySpot)
		)
	}

	static parseItineraryGroup(itineraryGroup: ApiItineraryGroup): ItineraryGroup {
		return {
			id: itineraryGroup.id,
			itineraryId: itineraryGroup.itinerary_id,
			name: itineraryGroup.text,
			color: itineraryGroup.color,
			lightColor: itineraryGroup.light_color,
		}
	}

	static parseItineraryGroups(itineraryGroups: ApiItineraryGroup[]): ItineraryGroup[] {
		return itineraryGroups.map((itineraryGroup) => this.parseItineraryGroup(itineraryGroup))
	}

	static parseItineraryCategory(itineraryCategory: ApiItineraryCategory): ItineraryCategory {
		return new ItineraryCategory({
			id: itineraryCategory.id,
			name: itineraryCategory.name,
			logo: itineraryCategory.logo,
		})
	}

	static parseItineraryCategories(
		itineraryCategories: ApiItineraryCategory[]
	): ItineraryCategory[] {
		return itineraryCategories.map((itineraryCategory) =>
			this.parseItineraryCategory(itineraryCategory)
		)
	}

	static toProduct = (product: ApiProduct) => {
		let price
		if (product.prices?.length) {
			const apiPrice = product.prices[0]
			const currency = new Currency({ code: apiPrice.currency })
			price = new Price({
				currency,
				value: apiPrice.price,
			})
		}

		return new Product({
			id: product.id,
			title: product.title,
			category: product.category,
			externalUrl: product.external_url,
			coverUrls: product.cover_urls,
			price,
		})
	}

	static toProducts = (products: ApiProduct[]) => {
		return products.map((product: ApiProduct) => this.toProduct(product))
	}
}
