



























import Vue from 'vue'
import { mapGetters } from 'vuex'
import Toast, { ToastPositions } from '~/components/UI/Toast.vue'

export default Vue.extend({
	name: 'Alerts',
	components: { Toast },
	data() {
		return {
			toastOptions: {
				defaultPosition: 'bottom-right' as ToastPositions,
				fixed: true,
			},
		}
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
			alerts: 'alerts/alerts',
		}),
		position(): ToastPositions {
			return this.screenSize.xs ? 'bottom' : this.toastOptions.defaultPosition
		},
		toastTransition(): string {
			const transitionOptions: { [key in ToastPositions]: string } = {
				'top': 'slide-in-top',
				'bottom': 'slide-in-bottom',
				'top-right': 'slide-in-right',
				'top-left': 'slide-in-left',
				'bottom-right': 'slide-in-right',
				'bottom-left': 'slide-in-left',
			}
			return transitionOptions[this.position]
		},
	},
	methods: {
		textAlert(alert: any) {
			return alert.index ? this.$t(alert.index, alert.data) : alert.text
		},
		closeAlert(id: number) {
			this.$store.dispatch('alerts/deleteAlert', id)
		},
	},
})
