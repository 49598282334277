import Price from './Price'

export default class Product {
	id: string
	title: string
	category: string
	price?: Price
	externalUrl: string
	coverUrls: string[]

	constructor(content: Product) {
		this.id = content.id
		this.title = content.title
		this.category = content.category
		this.price = content.price
		this.externalUrl = content.externalUrl
		this.coverUrls = content.coverUrls
	}
}
